// Salone2024Page.js
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import VideoBackgroundComponent from '../VideoBackground';
import { Fade, AttentionSeeker } from "react-awesome-reveal";

import PillButton from '../PillButton';
import PillButtonLight from '../PillButtonLight';
import CircularCard from '../CircularCard';
import Packages from '../PackagesSalone';

import Modal from '../Modal';
import AvvisoBanner from '../AvvisoBanner';
import FormContatto from '../FormContatto';


const PageSalone2024 = styled.div`

`;

const Salone2024Page = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

const [mostraAvviso, setMostraAvviso] = useState(false);

useEffect(() => {
  // Imposta un timer per mostrare l'avviso dopo 5 secondi
  const timer = setTimeout(() => {
    setMostraAvviso(false); //true attiva popup
  }, 4500);

  // Pulisce il timer quando il componente si smonta
  return () => {
    clearTimeout(timer);
  };
}, []); // L'array vuoto come secondo argomento fa sì che questo effetto venga eseguito solo una volta all'avvio



const [mostraFormContatto, setMostraFormContatto] = useState(false);
const [isInitialLoad, setIsInitialLoad] = useState(true); // Stato per il caricamento iniziale

const handleCloseAvviso = () => {
  setMostraAvviso(false);
};

const handleOpenFormContatto = () => {
  setMostraFormContatto(true);
};

const handleCloseFormContatto = () => {
  setMostraFormContatto(false);
};

useEffect(() => {
  // Blocca lo scroll del body solo durante il caricamento iniziale
  if (isInitialLoad) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }
}, [isInitialLoad]);

// Dopo il caricamento iniziale, imposta isInitialLoad su false
useEffect(() => {
  setIsInitialLoad(false);
}, []);

    const Salone2024 = () => {
      console.log('contact us');
    };

  const images = [
    './images/gallery/image0.jpg',
    './images/gallery/image1.jpg',
    './images/gallery/image2.jpg',
    './images/gallery/image3.jpg',
    './images/gallery/image4.jpg',
    './images/gallery/image5.jpg',
    './images/gallery/image6.jpg',
    './images/gallery/image7.jpg',
    './images/gallery/image8.jpg',
    './images/gallery/image9.jpg',
    './images/gallery/image10.jpg',
    './images/gallery/image11.jpg',
  ];

  const featuresRef = useRef(null);
  const bookingRef = useRef(null);

  const scrollToFeatures = () => {
    if (featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToBooking = () => {
    if (bookingRef.current) {
      bookingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <PageSalone2024>
        <VideoBackgroundComponent />

<Modal show={mostraAvviso} onClose={handleCloseAvviso}>
 <AvvisoBanner onClose={handleCloseAvviso} />
</Modal>



<Modal show={mostraFormContatto} onClose={handleCloseFormContatto}>
 <FormContatto onClose={handleCloseFormContatto} />
</Modal>


<div className='Home'>

<section id="header">
<img  src="./images/logo.svg" alt="Selfiebot Logo" onClick={scrollToTop} style={{ cursor: 'pointer' }} />
   <div>
   <Fade direction="right"><PillButton onClick={scrollToBooking}>PRENOTA</PillButton></Fade>
   </div>
 </section>

 <section id='opener'>

   <div id="opening-message">
     <Fade direction="left">
     <h1>SALONE DEL MOBILE SPECIAL EDITION</h1>
     <h2>Coinvolgi e sorprendi <br></br> gli ospiti del tuo evento.</h2>
     <p>Con Selfiebot, ogni istante diventa un'opportunità per creare leads e ricordi indimenticabili.</p>
     <PillButton onClick={scrollToFeatures}>Scopri di più</PillButton></Fade>
   </div>

   <div id="selfiebot">
     <img src='./images/selfiebot.png' alt='Selfiebot Image' />
   </div>
   
 </section>


 <div ref={featuresRef} id='features'>
   <div>


     <CircularCard
       imageUrl="./images/features-design.png"
       title="Il photobot innovativo per il tuo evento"
       text="Selfiebot rappresenta l'innovazione nel mondo dei Photobooth. Grazie al suo design di alta qualità, alla sua solidità e agilità, supera le tradizionali cabine fotografiche statiche, offrendo un'esperienza unica di photobooth in movimento; segue il pubblico dovunque durante ogni tipo di evento. Il light ring integrato garantisce l'illuminazione perfetta per ogni foto. I tuoi ospiti dovranno solo divertirsi, a lasciare loro un ricordo indimenticabile ci penserà Selfiebot."
     />

     <CircularCard
       imageUrl="./images/features-personal.png"
       title="Personalizzazione e Condivisione Massima"
       text="Il software utilizzato da Selfiebot è altamente personalizzabile, in grado di offrire funzionalità extra su misura. Inoltre, hai la possibilità di personalizzare la scocca del robot come preferisci, anche con il tuo logo. In più, Selfiebot consente la condivisione istantanea delle foto tramite e-mail o SMS, promuovendo l’uso dei contenuti generati sui social network; può cosi diventare un efficace strumento di lead generation per farti rimanere sempre in contatto con i tuoi ospiti."
       reverse={true}
     />

     <CircularCard
       imageUrl="./images/features-print.png"
       title="Stampe di Alta Qualità in Tempo Record"
       text="Selfiebot utilizza una stampante professionale che garantisce stampe di altissima qualità, con finitura di fascia alta, in soli 30 secondi per ciascuna stampa di formato 10x15cm. Le stampe vengono personalizzate con cornici grafiche e sovrapposizioni, offrendo un ricordo davvero speciale per i tuoi ospiti. Pochi secondi e le foto potranno essere nelle mani dei tuoi invitati pronte per essere commentate e condivise."
     />
   </div>
 </div>



 <Packages  scrollToBooking={scrollToBooking} ContactUs={handleOpenFormContatto} title={"Scegli l'offerta dedicata per il Salone del Mobile 2024"} price1={"1.300 €"} price2={"1.600 €"}/>


 <div id="prenota" ref={bookingRef}>
 
 <AttentionSeeker cascade damping={.75} effect='pulse'>
   <h2>Prenota Selfiebot per il Salone del Mobile 2024</h2>
 <h4>Compila il form qui sotto</h4>
 <iframe src='https://forms.zohopublic.eu/amigdala/form/SelfiebotBooking/formperma/inTsfI_wUAa0VE5IrKlO8sWiysmQb1UL6aamp3H1R4Y'></iframe>
 </AttentionSeeker>
 </div>
 
<div id="contattaci">
<AttentionSeeker cascade damping={.75} effect='tada'><h3>Hai altri dubbi o domande? <br></br>Vorresti acquistare un selfiebot?</h3>
<PillButtonLight onClick={handleOpenFormContatto}>CONTATTACI</PillButtonLight></AttentionSeeker>
</div>



<script type="text/javascript" src="https://campaigns.zoho.eu/js/zc.iframe.js"></script>
<iframe frameborder="0" id="iframewin" width="100%" height="100%" scrolling="no" src="https://fhzb-zcmp.maillist-manage.eu/ua/Optin?od=12ba7e9e1986&zx=14ad49f42d&lD=11f0b72ec80c7e75&n=11699f74cb47a4b&sD=11f0b72ec80d0b19"></iframe>

<div id="copyright">
 <h6>Powered by <a href='https://www.amigdala.ch/' target='_blank'>Amigdala</a></h6>
</div>



</div>
    </PageSalone2024>
  );
};

export default Salone2024Page;
