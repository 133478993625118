// AvvisoBanner.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const AvvisoBannerWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image:url('./images/banner.jpg');
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const AvvisoBanner = ({ onClose }) => {
  return (
 <AvvisoBannerWrapper>
    </AvvisoBannerWrapper>
  );
};

AvvisoBanner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AvvisoBanner;
