import React from 'react';
import styled from 'styled-components';
import { Fade, Slide, JackInTheBox } from "react-awesome-reveal";

const CircularCardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  
  width: 100%;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  margin: 0 auto;
  margin-bottom:4rem;

  @media (max-width: 768px) {
    flex-direction: column; // Cambia la direzione in colonna su schermi con larghezza massima di 768px
  }
`;

const CircularImage = styled.img`
  width: 320px;
  height: 320px;
  flex-shrink: 0;
  border-radius: 50%; /* Per rendere l'immagine circolare */
  margin-right: 20px;
  transition: 1s;

  &:hover {
    transform: scale(1.05);
   
  }

  @media (max-width: 768px) {
    margin-right: 0; // Rimuovi il margine destro su dispositivi mobili
    margin-bottom: 20px; // Aggiungi un margine inferiore per separare l'immagine e il testo
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

`;

const Title = styled.h3`
  margin: 0;
  color: #FFF;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  padding-bottom:1rem;
`;

const Description = styled.p`
  margin: 0;
  color: #FFF;
  font-family: Roboto Condensed;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.75;
`;

const CircularCard = ({ imageUrl, title, text, reverse }) => {
  return (
    <CircularCardContainer reverse={reverse}>
      {imageUrl && <JackInTheBox><CircularImage src={imageUrl} alt="Circular Image" /></JackInTheBox>}
      <Fade>
        <TextContainer>
        <Title>{title}</Title>
        <Description>{text}</Description>
      </TextContainer>
      </Fade>
    </CircularCardContainer>
  );
};

export default CircularCard;
