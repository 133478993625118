// FormContatto.js
import React from 'react';
import styled from 'styled-components';

const ContattoForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height:80vh;


  & iframe{
    border:0;
    width:100%;
    height:100%;
  }
`;

const FormContatto = () => {
  // Implementa il tuo form di contatto qui

  return (
    <ContattoForm>
        <iframe src='https://forms.zohopublic.eu/amigdala/form/Contact/formperma/n4jEMUkgnDnSyK7fLFRiBv4CKqnj_vP98jWd8daoQu4'></iframe>
    </ContattoForm>
  );
};

export default FormContatto;
