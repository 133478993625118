import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importa Routes e Route
import HomePage from './components/pages/HomePage'; 
import Salone2024Page from './components/pages/Salone2024'; 


function App() {
  return (
    <Router>
      <div className="App">
        <Routes> {/* Sostituisci <Switch> con <Routes> */}
          <Route path="/" element={<HomePage />} /> {/* Sostituisci 'component' con 'element' */}
          <Route path="/offerta_salone_2024" element={<Salone2024Page />} /> {/* Sostituisci 'component' con 'element' */}
          {/* Aggiungi altre rotte per le tue pagine qui */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;