import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { JackInTheBox } from "react-awesome-reveal";


const Title = styled.div`

  background-color:white;

  & h2{margin:0; padding:3rem;}
`;

const GalleryContainer = styled.div`
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  background-color:white;
 
  
  @media (max-width: 1924px) {
    overflow-x:scroll;
  }

  &::-webkit-scrollbar{
    display: none;
  }

`;

const ImageContainer = styled.div`
  width: 460px;
  height: 460px;
  margin: 2px;
  display: inline-block;
  margin:0px 20px 0px 20px;

  & div{ 
    width:100%;
    height:100%;
  }

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    margin:0px 10px 0px 10px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #ccc;
 /* cursor: pointer;*/
  object-fit: cover;
  transition: transform 0.2s;
  /*&:hover {
    transform: scale(1.03);
  }*/
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
`;

const NavigationArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`;


const LeftArrow = styled(NavigationArrow)`
display:none;
position: absolute;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Colore di sfondo */
  color: white; /* Colore del testo */
`;

const RightArrow = styled(NavigationArrow)`
display:none;
position: absolute;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Colore di sfondo */
  color: white; /* Colore del testo */
`;



const ImageGallery = ({ images }) => {
  const galleryRef = useRef(null);
  const [isDragging, setDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(null);
  const [enlargedImage, setEnlargedImage] = useState(null);

  const handleImageClick = (image) => {
   // setEnlargedImage(image);
  };

  const handleDragStart = (e) => {
    e.preventDefault();
    setDragging(true);
    setDragStartX(e.clientX);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDragMove = (e) => {
    if (!isDragging) return;
    const dx = e.clientX - dragStartX;
    if (galleryRef.current) {
      galleryRef.current.scrollLeft -= dx;
    }
    setDragStartX(e.clientX);
  };

  const closeModal = () => {
    setEnlargedImage(null);
  };

  const scrollLeft = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollLeft -= 100;
    }
  };

  const scrollRight = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollLeft += 100;
    }
  };

  return (
    <>
<Title><h2>Scorri la gallery fotografica</h2></Title>
      <GalleryContainer
        ref={galleryRef}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDragMove}
      >


      <LeftArrow onClick={scrollLeft}>{'<'}</LeftArrow>
      <RightArrow onClick={scrollRight}>{'>'}</RightArrow>
        {images.map((image, index) => (
          <ImageContainer key={index}>
            <JackInTheBox  cascade damping={0.1}><Image
              src={image}
              alt={`Image ${index}`}
              onClick={() => handleImageClick(image)}
            />
            </JackInTheBox>
          </ImageContainer>
        ))}
      </GalleryContainer>
      
      {enlargedImage && (
        <ModalContainer onClick={closeModal}>
          <ModalImage src={enlargedImage} alt="Enlarged" />
        </ModalContainer>
      )}
    </>
  );
};

export default ImageGallery;
