import styled from 'styled-components';

const PillButtonLight = styled.button`
  display: flex;
  padding: 16px 48px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: white;
  border:2px solid #000;
  border-radius: 217px;
  background: #fff;
  padding: 10px 20px;
  cursor: pointer;
  transition:0.4s;


  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 99.5%; /* 23.88px */

  &:hover {
    background-color: #2F2D30; /* Cambia il colore al passaggio del mouse */
    color:#FFF;
   
`;

export default PillButtonLight;
