// Modal.js
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Fade, AttentionSeeker } from "react-awesome-reveal";


const ModalOverlay = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  padding-top:80px;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  justify-content: center;
  align-items: center;
  align-content: center;
    flex-wrap: wrap;
    & div{
      width:100%;
      height:85vh;
    }  
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  
`;

const ModalClose = styled.span`
  background: white;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
`;

const Modal = ({ show, onClose, children }) => {
  useEffect(() => {
    // Blocca lo scroll del body quando il componente viene montato
    document.body.style.overflow = show ? 'hidden' : 'unset';
 
    // Pulisci l'effetto quando il componente viene smontato
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [show]);

  return (
    
    <ModalOverlay show={show} onClick={onClose}>
      <Fade triggerOnce duration={500}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalClose onClick={onClose}>Close</ModalClose>
        <AttentionSeeker triggerOnce delay={500} damping={.75} effect='pulse'> {children}</AttentionSeeker>
      </ModalContent>
      </Fade>
    </ModalOverlay>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default Modal;
