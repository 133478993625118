// PillButton.js
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
  display: flex;
  padding: 16px 48px !important;
  justify-content: center;
  align-items: center;
  gap: 10px; 

  color: white;
  border: none;
  border-radius: 217px;
  background: #000;
  padding: 10px 20px;
  cursor: pointer;
  transition:0.4s;
z-index:9999;

  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 99.5%; /* 23.88px */

  &:hover {
    background-color: #2F2D30; /* Cambia il colore al passaggio del mouse */
   
`;

const PillButton = ({ onClick, children }) => {
  return (
    <StyledButton onClick={onClick} type="button">
      {children}
    </StyledButton>
  );
};

PillButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default PillButton;