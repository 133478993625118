import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const VideoBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color:#000;
  z-index: -1;
  transition: opacity 0.5s; /* Añade una transición suave */
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity:.40;
`;

const VideoBackgroundComponent = () => {

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= window.innerHeight) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <VideoBackground hidden={hidden}>
      <Video autoPlay muted loop playsInline>
        <source src="/background-video.mp4" type="video/mp4" />
        Il vostro browser non supporta la riproduzione di video.
      </Video>
    </VideoBackground>
  );
};

export default VideoBackgroundComponent;
