// Importa le librerie necessarie
import React from 'react';
import styled from 'styled-components';
import PillButton from './PillButton';
import { Zoom } from "react-awesome-reveal";


// Stili per il componente Packages

const PackagesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: center;
  flex-wrap:wrap;
  justify-content: center;
  gap:4rem;
  margin-bottom: -4rem;
  z-index:20;
  width:100%;
  min-height:60vh;
  background-color:white;
`;

const PackagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: stretch;
  flex-wrap:wrap;
  justify-content: center;
  gap:1.5rem;

  z-index:20;
  width:100%;
  min-height:60vh;
  background-color:white;

  background-image: url('./images/bg-bottom.png');
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  margin-bottom: -2px;

`;


const PackagesContainerTitle = styled.div`
  width:100%;
  text-align:center;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  padding-bottom:3rem;
  color:white;
  margin-top: -3rem;

  @media (max-width: 768px) {
    margin-top: -11rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  color: #333; /* Colore personalizzato */
  margin-bottom: 20px;

  padding-left:120px;
  padding-right:120px;
`;


const PackageCard = styled.div`

  margin-top: 50px;
  margin-bottom: 150px;
  border: 1px solid #ccc;
  border-radius: 32px;
  padding: 8px;
  max-width: 340px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  margin-top:-30px;
  padding-bottom:30px;
  // Set background color based on the package index
  ${(props) =>
    props.packageIndex === 1
      ? 'background-color: white;'
      : props.packageIndex === 2
        ? 'background-color: #fff;color: black;'
        : 'background-color: white;'}  
        
        

`;

const PackageImage = styled.img`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 0px;
  margin-top:-60px;
`;

const PackageTitle = styled.h3`
  margin-bottom: 0px;
  font-size:25px;
`;

const PackageDescription = styled.h4`
  margin-bottom: 10px;
`;

const PackageFeatures = styled.ul`
  list-style: none;
  padding: 0;
  line-height:1.3;
  font-size:0.9rem;
  `;

const PackagePrice = styled.h3`
  list-style: none;
  padding: 0;
  font-size:0.9rem;
  & span{
    font-weight:100;
  }
  `;

const PackageFeature = styled.li`
  margin-bottom: 5px;
`;

const PackageNotes = styled.div`
font-size:0.8rem;
    margin-bottom: 10px;
    text-align:center;
    max-width:100%;
    padding: 3rem;
    display:none;
`;




// Componente Packages
const PackagesSalone = ({ scrollToBooking, ContactUs, title, subtitle, price1, price2, price3 }) => {
  return (

    <PackagesDiv>
      <PackagesContainer>
        <PackagesContainerTitle>{title ? <h2>{title}</h2> : <h2>Scegli il tuo Pacchetto</h2>}</PackagesContainerTitle>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}

        <Zoom>
          <PackageCard packageIndex={1}>
            <PackageImage src="./images/girls.jpg" alt="Half-Day" />
            <PackageTitle>Short Event</PackageTitle>
            <PackageDescription>Evento breve un party o un aperitivo</PackageDescription>
            <PackageFeatures>
              <PackageFeature> Personalizzazione grafica circolare anteriore</PackageFeature>
              <PackageFeature> Personalizzazione della cornice fotografica</PackageFeature>
              <PackageFeature> Personalizzazione di colori e logo</PackageFeature>
              <PackageFeature> Personalizzazione di testi email</PackageFeature>
              <PackageFeature> Stampa delle foto</PackageFeature>
              <PackageFeature> Operatore Selfiebot</PackageFeature>
              <PackageFeature> Montaggio e Trasporto</PackageFeature>

            </PackageFeatures>
            <PackagePrice>{price1 ? price1 : "1'600 €"} / max 3 ore</PackagePrice>
            <PillButton onClick={scrollToBooking}>PRENOTA SUBITO</PillButton>
          </PackageCard>
        </Zoom>
        <Zoom>
          <PackageCard packageIndex={2}>
            <PackageImage src="./images/zurich.jpg" alt="Full-Day" />
            <PackageTitle>One day Event</PackageTitle>
            <PackageDescription>Un intera giornata di evento</PackageDescription>
            <PackageFeatures>
              <PackageFeature> Personalizzazione grafica ant. e post.</PackageFeature>
              <PackageFeature> Personalizzazione della cornice fotografica</PackageFeature>
              <PackageFeature> Personalizzazione di colori e logo</PackageFeature>
              <PackageFeature> Personalizzazione di testi eMail ed sms</PackageFeature>
              <PackageFeature> Stampa delle foto</PackageFeature>
              <PackageFeature> Operatore Selfiebot</PackageFeature>
              <PackageFeature> Montaggio e Trasporto</PackageFeature>

              {/* Aggiungi ulteriori features se necessario */}
            </PackageFeatures>
            <PackagePrice> {price2 ? price2 : "2'000 €"} / max 8 Ore al gg</PackagePrice>

            <PillButton onClick={scrollToBooking}> PRENOTA SUBITO</PillButton>
          </PackageCard>
        </Zoom>

        <Zoom>
          <PackageCard packageIndex={3}>
            <PackageImage src="./images/salone.jpg" alt="Full-Day" />
            <PackageTitle>dal 19 al 21 Aprile</PackageTitle>
            <PackageDescription>Speciale Salone del Mobile</PackageDescription>
            <PackageFeatures>

            <PackageFeature> Personalizzazione grafica ant. e post.</PackageFeature>
              <PackageFeature> Personalizzazione della cornice fotografica</PackageFeature>
              <PackageFeature> Personalizzazione di colori e logo</PackageFeature>
              <PackageFeature> Personalizzazione di testi eMail ed sms</PackageFeature>
              <PackageFeature> Stampa delle foto</PackageFeature>
              <PackageFeature> Operatore Selfiebot</PackageFeature>
              <PackageFeature> Montaggio e Trasporto</PackageFeature>
            </PackageFeatures>

            <PackagePrice>4'000 € / max 8 Ore al gg</PackagePrice>
          <PillButton onClick={scrollToBooking}> PRENOTA SUBITO</PillButton>
          </PackageCard>

        </Zoom>

        <Zoom>
          <PackageCard packageIndex={4}>
            <PackageImage src="./images/salone.jpg" alt="Full-Day" />
            <PackageTitle>dal 16 al 21 Aprile</PackageTitle>
            <PackageDescription>Speciale Full Days Salone del Mobile</PackageDescription>
            <PackageFeatures>

            <PackageFeature> Personalizzazione grafica ant. e post.</PackageFeature>
              <PackageFeature> Personalizzazione della cornice fotografica</PackageFeature>
              <PackageFeature> Personalizzazione di colori e logo</PackageFeature>
              <PackageFeature> Personalizzazione di testi eMail ed sms</PackageFeature>
              <PackageFeature> Stampa delle foto</PackageFeature>
              <PackageFeature> Operatore Selfiebot</PackageFeature>
              <PackageFeature> Montaggio e Trasporto</PackageFeature>
            </PackageFeatures>
            <PackagePrice>7'500 € / max 8 Ore al gg</PackagePrice>
            <PillButton onClick={scrollToBooking}> PRENOTA SUBITO</PillButton>
          </PackageCard>

        </Zoom>


      </PackagesContainer>



      <PackageNotes>


        <p><b>Esclusioni:</b> Certificazioni, Permessi, Diaria, Parcheggio, Fermo macchina durante evento, Fornitura elettrica e Assicurazione.
        </p>
        <p>Grafiche di personalizzazione da fornire sulla base dei template forniti.<br></br>
          Il trasporto al di fuori del Comune di Milano e hinterland è da calcolare come extra.</p>

        <p>Le ore di noleggio sono da considerarsi consecutive. Il noleggio include 1 ora di stop dopo le 4 ore di lavoro per la ricarica della batteria. <br></br>Eventuali ore extra verranno calcolate e fatturate a consuntivo al prezzo di listino.</p>
        <p>Il pagamento del noleggio è 100% anticipato e non rimborsabile in caso di annullamento della prenotazione.</p>
      </PackageNotes>

    </PackagesDiv>
  );
};

export default PackagesSalone;
