// HomePage.js
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import VideoBackgroundComponent from '../VideoBackground';
import { Fade, AttentionSeeker } from "react-awesome-reveal";

import PillButton from '../PillButton';
import PillButtonLight from '../PillButtonLight';
import CircularCard from '../CircularCard';
import ImageGallery from '../ImageGallery';
import Packages from '../Packages';

import Modal from '../Modal';
import AvvisoBanner from '../AvvisoBanner';
import FormContatto from '../FormContatto';


const PageHome = styled.div`

`;



const HomePage = () => {



    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
  
  const [mostraAvviso, setMostraAvviso] = useState(false);
  
  useEffect(() => {
    // Imposta un timer per mostrare l'avviso dopo 5 secondi
    const timer = setTimeout(() => {
      setMostraAvviso(false); //true attiva popup
    }, 4500);
  
    // Pulisce il timer quando il componente si smonta
    return () => {
      clearTimeout(timer);
    };
  }, []); // L'array vuoto come secondo argomento fa sì che questo effetto venga eseguito solo una volta all'avvio
  
  
  
  const [mostraFormContatto, setMostraFormContatto] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Stato per il caricamento iniziale
  
  const handleCloseAvviso = () => {
    setMostraAvviso(false);
  };
  
  const handleOpenFormContatto = () => {
    setMostraFormContatto(true);
  };
  
  const handleCloseFormContatto = () => {
    setMostraFormContatto(false);
  };
  
  useEffect(() => {
    // Blocca lo scroll del body solo durante il caricamento iniziale
    if (isInitialLoad) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isInitialLoad]);
  
  // Dopo il caricamento iniziale, imposta isInitialLoad su false
  useEffect(() => {
    setIsInitialLoad(false);
  }, []);
  
      const ContactUs = () => {
        console.log('contact us');
      };
  
    const images = [
      './images/gallery/image0.jpg',
      './images/gallery/image1.jpg',
      './images/gallery/image2.jpg',
      './images/gallery/image3.jpg',
      './images/gallery/image4.jpg',
      './images/gallery/image5.jpg',
      './images/gallery/image6.jpg',
      './images/gallery/image7.jpg',
      './images/gallery/image8.jpg',
      './images/gallery/image9.jpg',
      './images/gallery/image10.jpg',
      './images/gallery/image11.jpg',
    ];
  
    const featuresRef = useRef(null);
    const bookingRef = useRef(null);
  
    const scrollToFeatures = () => {
      if (featuresRef.current) {
        featuresRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    const scrollToBooking = () => {
      if (bookingRef.current) {
        bookingRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

  return (
    <PageHome>
       <VideoBackgroundComponent />

       <Modal show={mostraAvviso} onClose={handleCloseAvviso}>
        <AvvisoBanner onClose={handleCloseAvviso} />
      </Modal>

  

      <Modal show={mostraFormContatto} onClose={handleCloseFormContatto}>
        <FormContatto onClose={handleCloseFormContatto} />
      </Modal>


      <div className='Home'>

      <section id="header">
     <img  src="./images/logo.svg" alt="Selfiebot Logo" onClick={scrollToTop} style={{ cursor: 'pointer' }} />
          <div>
          <Fade direction="right">  <PillButton onClick={scrollToBooking}>PRENOTA</PillButton></Fade>
          </div>
        </section>

        <section id='opener'>

          <div id="opening-message">
            <Fade direction="left">
            <h1>Il primo<br></br> photobooth <br></br>in movimento<br></br> al mondo</h1>
            <PillButton onClick={scrollToFeatures}>Scopri di più</PillButton></Fade>
          </div>
 
          <div id="selfiebot">
            <img src='./images/selfiebot.png' alt='Selfiebot Image' />
          </div>
          
        </section>


        <div ref={featuresRef} id='features'>
          <div>


            <CircularCard
              imageUrl="./images/features-design.png"
              title="Un Photobooth Innovativo per Eventi"
              text="Selfiebot rappresenta l'innovazione nel mondo dei Photo Booth. Grazie al suo design di alta qualità, supera le tradizionali cabine fotografiche statiche, offrendo un'esperienza unica di un photobooth in movimento, in grado di seguire il pubblico dovunque, anche sulla pista da ballo. La sua robustezza e mobilità lo rendono ideale per ogni tipo di evento. Il light ring integrato garantisce l'illuminazione perfetta per ogni foto. I tuoi ospiti dovranno solo divertirsi, a lasciare loro un ricordo indimenticabile ci penserà Selfiebot."
            />

            <CircularCard
              imageUrl="./images/features-personal.png"
              title="Personalizzazione e Condivisione Massima"
              text="Il software utilizzato da Selfiebot è altamente personalizzabile, in grado di offrire funzionalità extra su misura. Inoltre, hai la possibilità di personalizzare la scocca del robot come preferisci, anche con il tuo marchio. 
              In più, Selfiebot consente la condivisione istantanea delle foto tramite e-mail o SMS, promuovendo l’uso dei contenuti generati sui social network; può cosi diventare un efficace  strumento di raccolta dati per farti rimanere sempre in contatto con i tuoi ospiti. "
              reverse={true}
            />

            <CircularCard
              imageUrl="./images/features-print.png"
              title="Stampe di Alta Qualità in Tempo Record"
              text="Selfiebot utilizza una stampante professionale che garantisce stampe di altissima qualità, con finitura di fascia alta, in soli 30 secondi per ciascuna stampa di formato 10x15cm. Le stampe vengono personalizzate con cornici grafiche e sovrapposizioni, offrendo un ricordo davvero speciale per i tuoi ospiti. Pochi secondi e le foto potranno essere nelle mani dei tuoi invitati pronte per essere commentate e condivise."
            />
          </div>
        </div>

        <Packages  scrollToBooking={scrollToBooking} ContactUs={handleOpenFormContatto}/>

        <div id="imageGallery">

          <ImageGallery images={images} />
        </div>

        

        

        <div id="prenota" ref={bookingRef}>
        
        <AttentionSeeker cascade damping={.75} effect='pulse'>
          <h2>Prenota Selfiebot per il tuo Evento</h2>
        <h4>Compila il form qui sotto</h4>
        <iframe src='https://forms.zohopublic.eu/amigdala/form/SelfiebotBooking/formperma/inTsfI_wUAa0VE5IrKlO8sWiysmQb1UL6aamp3H1R4Y'></iframe>
        </AttentionSeeker>
        </div>
        
      <div id="contattaci">
      <AttentionSeeker cascade damping={.75} effect='tada'><h3>Hai altri dubbi o domande? <br></br>Vorresti acquistare un selfiebot?</h3>
       <PillButtonLight onClick={handleOpenFormContatto}>CONTATTACI</PillButtonLight></AttentionSeeker>
      </div>


      
      <script type="text/javascript" src="https://campaigns.zoho.eu/js/zc.iframe.js"></script>
<iframe frameborder="0" id="iframewin" width="100%" height="100%" scrolling="no" src="https://fhzb-zcmp.maillist-manage.eu/ua/Optin?od=12ba7e9e1986&zx=14ad49f42d&lD=11f0b72ec80c7e75&n=11699f74cb47a4b&sD=11f0b72ec80d0b19"></iframe>

      <div id="copyright">
        <h6>Powered by <a href='https://www.amigdala.ch/' target='_blank'>Amigdala</a></h6>
      </div>

      

      </div>
    </PageHome>
  );
};

export default HomePage;
